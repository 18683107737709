<template>
  <div
    class="d-flex justify-content-center spinner"
  >
    <div
      class="spinner-border text-primary m-5"
      role="status"
    >
      <span class="sr-only" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Spinner'
};
</script>
<style>
.spinner {
  z-index: 20;
}
</style>


