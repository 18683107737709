<template>
  <button
    v-if="isVisible"
    :class="buttonClass"
    class="btn btn-sm"
    :disabled="isDisabled"
    @click="btnClickedHandler"
  >
    {{ buttonText }}
  </button>
</template>
<script>
export default {
  computed: {
    isDisabled() {
      if (typeof this.params.isDisabled === 'function') {
        return this.params.isDisabled(this.params.node.data);
      }
      return this.params.isDisabled;
    },
    isVisible() {
      if (!this.params.visibilityCheck) {
        return true;
      }
      return this.params.visibilityCheck(this.params.node.data);
    },
    buttonText() {
      return this.params.buttonText || 'Edit';
    },
    buttonClass() {
      return this.params.buttonVariant || 'btn-primary';
    }
  },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params.value);
    }
  }
};
</script>
