<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <div class="card m-auto forecasts-container">
          <div class="card-header p-3 d-flex justify-content-between">
            <h5 class="mt-1 mb-0">
              Forecasts
            </h5>
            <h5 class="mt-1 mb-0">
              <input
                id="filter-text-box"
                v-model="searchQuery"
                type="text"
                placeholder="Search..."
              />
            </h5>
          </div>
          <div
            v-if="forecastData.length"
            class="card-body p-3 d-flex justify-content-center"
            style="height: 50em;"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :pagination="true"
              :paginationAutoPageSize="true"
              :rowData="filteredItems"
              class="ag-theme-alpine h-100 w-100"
            />
          </div>
          <Spinner v-else />
        </div>
        <div class="container-fluid d-flex justify-content-center mt-5">
          <div
            class="blue-link"
            @click="getForecastTemplate"
          >
            Download forecast template
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import Spinner from '@/components/common/Spinner';
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';

export default {
  components: {
    Spinner,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      searchQuery: '',
      rowData: []
    };
  },
  computed: {
    ...mapGetters({
      forecastData: 'forecast/lastUploadForecasts',
      actualBrands: 'forecast/companyItems'
    }),
    displayableForecastData() {
      return _.map(this.forecastData, (item) => {
        item.client = this.actualBrands[item['brandId']]?.name;
        return item;
      });
    },
    filteredItems() {
      const queryTrim = this.searchQuery.trim();
      if (queryTrim.length < 1) {
        return this.displayableForecastData;
      }
      const query = queryTrim.toLowerCase();
      return this.displayableForecastData.filter((item) => {
        return item.client.toLowerCase().includes(query);
      });
    },
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return [
        {
          headerName: 'Brand',
          field: 'client',
          sortable: true,
          width: 260
        },
        {
          headerName: 'Last upload date',
          field: 'uploadedDate',
          width: 230,
          sortable: true,
          valueFormatter: ({ value }) => this.formatDate(value)
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 230,
          sortable: true
        },
        {
          headerName: '',
          field: 'brandId',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'see all',
            buttonVariant: 'btn-primary',
            clicked: (brandId) => {
              this.redirectForecastClickHandler(brandId);
            }
          },
          width: 130
        }
      ];
    }
  },
  async mounted() {
    await this.fetchLastUploadForecasts();
    await this.fetchActualBrands();
  },
  methods: {
    ...mapActions({
      fetchLastUploadForecasts: 'forecast/fetchLastUploadForecasts',
      downloadForecastTemplate: 'forecast/getForecastTemplate',
      fetchActualBrands: 'forecast/fetchCompanyItems'
    }),
    redirectForecastClickHandler(brandId) {
      return this.$router.push(`/forecasts/${brandId}`);
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        });
      }
    },
    async getForecastTemplate(){
      await this.downloadForecastTemplate();
    }
  }
};
</script>
<style>
.forecasts-container {
  max-width: 900px;
}
.blue-link {
  color: #0d6efd;
  text-decoration: underline;
  transition: color 0.3s, cursor 0.3s;
}
.blue-link:hover {
  cursor: pointer;
  color: #0f489c;
}
</style>
